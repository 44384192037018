import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../Api/Api";
import PropTypes from "prop-types";
import { useState } from "react";

const DepartmentObjective = (props) => {
  DepartmentObjective.propTypes = {
    iconData: PropTypes.any,
    addNewObjName: PropTypes.any,
    addNewObjDesc: PropTypes.any,
    setNewObjNameError: PropTypes.any,
    setNewObjDescError: PropTypes.any,
    addNewObjective: PropTypes.any,
    singleObjectiveData: PropTypes.any,
    setEditedObjectives: PropTypes.any,
    getAllDeptObjList: PropTypes.any,
    setAddNewObjective: PropTypes.any,
    setAddNewObjName: PropTypes.any,
    setAddNewObjDesc: PropTypes.any,
    newObjNameError: PropTypes.any,
    newObjDescError: PropTypes.any,
    editedObjectives: PropTypes.any,
    setSingleObjectiveData: PropTypes.any,
    handleNewObjSubmit: PropTypes.any,
    // multiDeptUpdateError: PropTypes.any,
    // setMultiDeptUpdateError: PropTypes.any
  };


  const [deleteID, setObjDeleteID] = useState(false);

  const handleOpenModal = (id) => {
    setObjDeleteID(id);
    const modalElement = document.getElementById('objectiveDeleteModal');
    modalElement.classList.add('show');
    modalElement.style.display = 'block';
    document.body.classList.add('modal-open');
  };

  const handleCloseModal = () => {
    const modalElement = document.getElementById('objectiveDeleteModal');
    modalElement.classList.remove('show');
    modalElement.style.display = 'none';
    document.body.classList.remove('modal-open');
    const backdrop = document.querySelector('.modal-backdrop');
    if (backdrop) {
      backdrop.remove();
    }
  };
  
  const deleteDepartmentObj = () => {
    API.Auth.DeleteOrgDepartment({
      data: { _id: deleteID },
    })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          toast.success(response?.data?.message);
          console.log(response?.data);
          API.Department.getDeptObjListByOrg({
            data: {
              objectiveId: props?.iconData?._id,
            },
          })
            .then((response) => {
              if (response.data.statusCode === 200) {
                props?.setSingleObjectiveData(response?.data?.data);
                // const taskClick =
                //   document.getElementById("objectiveDeleteModal");
                // taskClick?.click();
                handleCloseModal()
              } else {
                toast.error(response?.data?.message);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };



  return (
    <div
      className="tab-pane fade show active"
      id="pills-profile"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    >
      <p className="col-md-8 mx-auto mb-2 mt-4 lightGrey">
        Change your objective and its respective task according to the updated
        organizational objective
      </p>
      <div className="col col-md-8 mx-auto p-3 bg-blue-fait rounded-2 mb-5">
        <p className="mb-2 fw-semobold textBlueLight1">
          {props?.iconData?.objective_name}
        </p>
        <p className="fw-regular textDarkblack mb-0">
          {props?.iconData?.description}
        </p>
      </div>
      <div className="col p-0">
        <div
          id="carousel-objective-controls"
          className="carousel slide col-md-12 mx-auto"
          data-bs-ride="carousel"
          data-interval="false"
        >
          {props?.addNewObjective ? (
            <div className="carousel-inner">
              <div className={"carousel-item active"}>
                <div className="button-outer d-block pb-4"></div>
                <p className="textDarkGrey fw-medium d-block">
                  Objective {props?.singleObjectiveData?.length + 1}
                </p>
                <div className="mb-3">
                  <div className="p-3 bg-gray rounded-2">
                    <input
                      type="text"
                      name="add_new_obj_name"
                      value={props?.addNewObjName}
                      onChange={(e) => {
                        props?.setAddNewObjName(e.target.value);
                      }}
                      // maxLength="30"
                      className="fw-semobold edit-objective-input textDarkGrey bg-gray rounded-2 mb-0"
                      placeholder="Enter department objective"
                      id="exampleInputfirstname"
                    />
                  </div>
                  {props?.newObjNameError ? (
                    <div className="text-danger mb-3">
                      {props?.newObjNameError}
                    </div>
                  ) : null}
                </div>
                <div className="p-3 bg-gray rounded-2 min-height-100">
                  <input
                    type="text"
                    name="add_new_obj_description"
                    value={props?.addNewObjDesc}
                    onChange={(e) => {
                      props?.setAddNewObjDesc(e.target.value);
                    }}
                    // maxLength="30"
                    className="fw-semobold edit-objective-input textDarkGrey bg-gray rounded-2 mb-0"
                    placeholder="Enter objective description"
                    id="exampleInputfirstname"
                  />
                </div>
                {props?.newObjDescError ? (
                  <div className="text-danger">{props?.newObjDescError}</div>
                ) : null}
              </div>
            </div>
          ) : (
            <div className="carousel-inner">
              {props?.singleObjectiveData?.length > 0 ? (
                props?.singleObjectiveData?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        index === 0 ? "carousel-item active" : "carousel-item"
                      }
                    >
                      <div className="button-outer d-block pb-4">
                        {/* <button
                          type="button"
                          data-bs-target="#objectiveDeleteModal"
                          data-bs-toggle="modal"
                          className="btn btn-primary float-right delete-custom-btn d-flex"
                          style={{ float: "right" }}
                          onClick={() => {
                            getDeleteId(item?._id);
                          }}
                        > */}
                           <button
        type="button"
        className="btn btn-primary float-right delete-custom-btn d-flex"
        style={{ float: "right" }}
        onClick={() => handleOpenModal(item?._id)}
      >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"></path>
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"></path>
                          </svg>
                        </button>
                      </div>
                      <p className="textDarkGrey fw-medium d-block">
                        Objective {index + 1}
                      </p>
                      <div className="p-3 bg-gray rounded-2 mb-3">
                        <input
                          type="text"
                          className="fw-semobold edit-objective-input textDarkGrey bg-gray rounded-2 mb-0"
                          value={
                            props?.editedObjectives[index]?.dept_objective_name
                          }
                          onChange={(event) => {
                            const updatedObjectives = [
                              ...props.editedObjectives,
                            ];
                            updatedObjectives[index].dept_objective_name =
                              event.target.value;
                            props?.setEditedObjectives(updatedObjectives);
                          }}
                        />
                      </div>
                      <div className="p-3 bg-gray rounded-2 mb-3 min-height-100">
                        <input
                          type="text"
                          className="fw-semobold edit-objective-input textDarkGrey bg-gray mb-0 border-0"
                          value={
                            props?.editedObjectives[index]
                              ?.dept_objective_description
                          }
                          onChange={(event) => {
                            const updatedObjectives = [
                              ...props.editedObjectives,
                            ];
                            updatedObjectives[
                              index
                            ].dept_objective_description = event.target.value;
                            props?.setEditedObjectives(updatedObjectives);
                          }}
                        />
                      </div>
                      {/* {props?.multiDeptUpdateError ? (
                                                <div className="text-danger mb-3">
                                                    {props?.multiDeptUpdateError}
                                                </div>
                                            ) : null} */}
                    </div>
                  );
                })
              ) : (
                <>
                  <h4
                    className="d-flex justify-content-center"
                    style={{ height: "100%", margin: "20px 0px 20px 0px" }}
                  >
                    No objectives found!
                  </h4>
                </>
              )}
            </div>
          )}

          <div className="col-12 btn-groups d-flex justify-content-between mt-3">
            <button
              type="button"
              className="btn btn-primary width-max-content rounded-pill ps-4 pe-4 min-width-255 pt-2 pb-2 blue-btn"
              onClick={() => {
                props?.setAddNewObjective(true);
                // props?.setMultiDeptUpdateError("")
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-plus"
                viewBox="0 0 16 16"
              >
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"></path>
              </svg>
              Add New
            </button>
            {props?.addNewObjective ? (
              <button
                type="submit"
                className="btn btn-primary width-max-content rounded-pill ps-4 pe-4 min-width-255  pt-2 pb-2"
                // disabled={!EditObjFormik.isValid}
                onClick={() => {
                  props?.handleNewObjSubmit();
                }}
                disabled={!props?.addNewObjName || !props?.addNewObjDesc}
              >
                Save
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-primary width-max-content rounded-pill ps-4 pe-4 min-width-255  pt-2 pb-2"
                // disabled={!EditObjFormik.isValid}
                onClick={() => {
                  props?.handleNewObjSubmit();
                }}
              >
                Save
              </button>
            )}
          </div>
          {!props?.addNewObjective && (
            <button
              className="carousel-control-prev objective-prev-btn "
              type="button"
              data-bs-target="#carousel-objective-controls"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon "
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
          )}
          {!props?.addNewObjective && (
            <button
              className="carousel-control-next objective-next-btn"
              type="button"
              data-bs-target="#carousel-objective-controls"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          )}
        </div>
      </div>

      <div
        className="modal fade"
        id="objectiveDeleteModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="objectiveDeleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered justify-content-center">
          <div className="modal-content p-5 border" style={{ width: "50%" }}>
            <div className="modal-body text-start text-center">
              <h2 className="textGrey">
                Are you sure you want to delete this objective?
              </h2>
              <div className="d-flex justify-content-center mt-3">
                <button
                  type="button"
                  className="btn btnOrganization w-auto px-5 mt-3 me-3"
                  // data-bs-dismiss="modal"
                  onClick={()=>handleCloseModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn sign-up-button-color text-white px-5 mt-3"
                  onClick={() => deleteDepartmentObj()}
                >
                  Delete
                </button>
              </div>
              <br />
              <p><span>Note : </span> Deleting this objective will also remove all associated tasks.</p>
            </div>
          </div>
        </div>
      </div>




    </div>

  );
};

export default DepartmentObjective;
