/* eslint-disable */
import React, { useState, useEffect } from "react";
import setObjective from "../../../assets/images/Icons/Pics/Set Objective.png";
import improveProductivity from "../../../assets/images/Icons/SVG/Selected Org.svg";
import timelyDelivery from "../../../assets/images/Icons/Pics/Department/SLA.png";
import editIcon from "../../../assets/images/Icons/SVG/Edit Icon.svg";
import deleteIcon from "../../../assets/images/Icons/SVG/Delete Icon.svg";
import API from "../../../Api/Api";
import { useNavigate } from "react-router-dom";
import RouteName from "../../../Routes/Routename";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const SetObjective = (props) => {
   const [objectiveList, setObjectiveList] = useState(null);
   const DepatmentUser = JSON.parse(localStorage.getItem("DepartmentUser"));

   const [DepartmentList, setDepartmentList] = useState(JSON.parse(localStorage.getItem("DepartmentList")) || null);
   const [Editid, setEditID] = useState(null);
   const [isEditing, setEditing] = useState(false); // New state for edit mode

   const [deleteId, setDeleteId] = useState();
   const [isloading, setIsLoading] = useState(false);

   const navigate = useNavigate();

   const getOrganizationalList = () => {
      API.Auth.OrganizationObjectiveList({
         data: {
            organization_id: DepatmentUser?.organization_id,
         },
      })
         .then((response) => {
            if (response.data.statusCode === 200) {
               console.log(response);
               setObjectiveList(response.data.data);
            }
         })
         .catch((err) => {
            console.log(err);
         });
   };

   useEffect(() => {
      getOrganizationalList();
      getAllDeptList();
   }, []);
   // const validate = (values) => {
   //   console.log(values, "value");
   //   const spaceReg = /^\S.*\S$/;
   //   const paragraphReg = /^[^\s]+(\s+[^\s]+)*$/

   //   const errors = {};
   //   if (!values.dept_objective_name) {
   //     errors.dept_objective_name = "Please enter department objective  name";
   //   } else if (!spaceReg.test(values.dept_objective_name)) {
   //     errors.dept_objective_name = "Cannot start & end with a space";
   //   }
   //   if (!values.dept_objective_description) {
   //     errors.dept_objective_description =
   //       "Please enter department objective description";
   //   } else if (!paragraphReg.test(values.dept_objective_description)) {
   //     errors.dept_objective_description = "Cannot start & end with a space";
   //   }
   //   if (!values.objective_id) {
   //     errors.objective_id = "Please select department objective";
   //   }
   //   if(values?.objective_id)

   //   console.log("Erroes", errors);
   //   return errors;
   // };

   const validate = (values) => {
      const spaceReg = /^\S.*\S$/;
      const paragraphReg = /^[^\s]+(\s+[^\s]+)*$/;

      const errors = {};
      if (!values.dept_objective_name) {
         errors.dept_objective_name = "Please enter department objective name";
      } else if (!spaceReg.test(values.dept_objective_name)) {
         errors.dept_objective_name = "Cannot start & end with a space";
      } else {
         const selectedObjective = formik.values.objective_id;
         const existingObjectives = DepartmentList?.filter(
            (item) => item.objective_id?.objectiveId === selectedObjective?.objectiveId
         );
         const matchingObjective = existingObjectives?.find((item) => item.dept_objective_name === values.dept_objective_name);

         if (matchingObjective) {
            errors.dept_objective_name = `Entered objective already exists under ${selectedObjective.objectiveName}`;
         }
      }

      if (!values.dept_objective_description) {
         errors.dept_objective_description = "Please enter department objective description";
      } else if (!paragraphReg.test(values.dept_objective_description)) {
         errors.dept_objective_description = "Cannot start & end with a space";
      }

      if (!values.objective_id) {
         errors.objective_id = "Please select organizational objective";
      }

      return errors;
   };

   const formik = useFormik({
      initialValues: {
         dept_objective_name: "",
         dept_objective_description: "",
         objective_id: "",
      },
      onSubmit: (values) => {
         console.log(values, "hello values");
         console.log("DepartmentList", DepartmentList);
         if (Editid) {
            handleUpdate();
         } else {
            createDeptObjective();
         }
      },
      validate,
   });

   const createDeptObjective = () => {
      const newObjective = {
         objective_id: formik.values.objective_id,
         dept_objective_name: formik.values.dept_objective_name,
         dept_objective_description: formik.values.dept_objective_description,
      };
      const newList = DepartmentList ? [...DepartmentList, newObjective] : [newObjective];
      setDepartmentList(newList);
      localStorage.setItem("DepartmentList", JSON.stringify(newList));
      formik.resetForm();
   };

   // get all Department list
   const getAllDeptList = () => {
      // API.Auth.OrgObjDepartmentList({
      //   data: {
      //     departmentId: props?.UsersData?._id,
      //     organizationId: props?.UsersData?.organization_id
      //   }
      // })
      //   .then((response) => {
      //     if (response.data.statusCode === 200) {
      //       setDepartmentList(response.data.data);
      //       console.log(response);
      //     }
      //   })
      //   .catch((err) => console.log(err));
   };
   const handleEdit = (objName) => {
      const objectiveToEdit = DepartmentList.find((item) => item?.dept_objective_name === objName);
      console.log("edit obj", objectiveToEdit);
      if (objectiveToEdit) {
         formik.setValues({
            dept_objective_name: objectiveToEdit.dept_objective_name,
            dept_objective_description: objectiveToEdit.dept_objective_description,
            objective_id: objectiveToEdit.objective_id,
         });
         setEditing(true);
         setEditID(objName);
      }
   };

   const handleUpdate = () => {
      setIsLoading(true);
      console.log(Editid, "Editid");
      console.log("dept listtttttttttt", DepartmentList);
      const indexToUpdate = DepartmentList.findIndex((item) => item?.dept_objective_name === Editid);
      console.log(indexToUpdate, "indexToUpdate");

      // Update the objective if found
      if (indexToUpdate !== -1) {
         const updatedList = [...DepartmentList];
         updatedList[indexToUpdate] = {
            ...updatedList[indexToUpdate],
            objective_id: formik.values.objective_id,
            dept_objective_name: formik.values.dept_objective_name,
            dept_objective_description: formik.values.dept_objective_description,
         };
         setDepartmentList(updatedList);
         localStorage.setItem("DepartmentList", JSON.stringify(updatedList));
         formik.resetForm();
         setEditing(false);
         setEditID(null);
         setIsLoading(false);
      }
   };

   const handleDelete = (objName) => {
      console.log(objName, "objName");
      setDeleteId(objName);
   };

   // Deelete API
   const deleteDepartment = () => {
      console.log(deleteId, "deleteId");
      console.log("dept listtttttttttt", DepartmentList);
      const filteredList = DepartmentList.filter((item) => item?.dept_objective_name !== deleteId);

      console.log(filteredList, "filteredList");

      setDepartmentList(filteredList);
      localStorage.setItem("DepartmentList", JSON.stringify(filteredList));
      document.querySelector("#DeleteDepartemnt").click();
      formik.resetForm();
      setEditing(false);
      setEditID(null);
   };

   const handleCancel = (event) => {
      event.preventDefault();
      formik.resetForm();
      setEditing(false);
      setEditID(null);
   };
   const handleNextButton = () => {
      // const data = {
      //   department_objectives_array: DepartmentList?.map((item) => ({
      //     ...item,
      //     objective_id: item?.objective_id?.objectiveId,
      //   })),
      //   flag: 3,
      //   departmentId: DepatmentUser._id,
      //   organizationId: DepatmentUser?.organization_id,
      // };

      API.Auth.RegisterAPI({
         data: {
            department_objectives_array: DepartmentList?.map((item) => ({
               ...item,
               objective_id: item?.objective_id?.objectiveId,
            })),
            flag: 3,
            departmentId: DepatmentUser._id,
            organizationId: DepatmentUser?.organization_id,
         },
      })
         .then((response) => {
            if (response?.data?.statusCode === 200) {
               props.handleNext();
               navigate(`../${RouteName.Auth.Registration}?id=3`, { replace: true });
               JSON.parse(localStorage.removeItem("DepartmentList"));
            }
         })
         .catch((err) => {
            console.log(err);
         });
   };

   console.log("props", props);

   return (
      <div className="mt-4">
         <div className="row justify-content-center mt-4">
            <div className="col-xxl-11 col-12">
               <h2 className="textBlue fw-normal">Set Objective</h2>
               <p className="textGrey">Make sure your objectives are crisp and clear</p>
               <div className="row">
                  <div className="col-xl-6 pe-xl-5 pb-5 border-right">
                     <p className="textBlueLight">Select organizational objective and set your objective</p>
                     {/* <p className="textLightGrey">Objective 1</p> */}
                     <form>
                        <div className="mb-4">
                           <div className="form-group">
                              <select
                                 className="form-select form-control"
                                 aria-label="Default select example"
                                 id="definedepartment"
                                 name="objective_id"
                                 onChange={(e) => {
                                    const selectedIndex = e.target.value;
                                    if (selectedIndex !== "") {
                                       const selectedOptionData = objectiveList[selectedIndex];
                                       const selectedObject = {
                                          objectiveId: selectedOptionData?._id,
                                          objectiveName: selectedOptionData?.objective_name,
                                       };
                                       formik.setFieldValue("objective_id", selectedObject);
                                    } else {
                                       formik.setFieldValue("objective_id", null);
                                    }
                                 }}
                                 value={
                                    formik.values.objective_id
                                       ? objectiveList.findIndex((item) => item._id === formik.values.objective_id.objectiveId)
                                       : ""
                                 }
                              >
                                 <option value="" disabled>
                                    Select organizational objective
                                 </option>
                                 {objectiveList &&
                                    objectiveList.map((item, index) => (
                                       <option key={item?._id} value={index}>
                                          {item?.objective_name}
                                       </option>
                                    ))}
                              </select>
                           </div>

                           {formik.errors.objective_id && formik.touched.objective_id ? (
                              <div className="text-danger">{formik.errors.objective_id}</div>
                           ) : null}
                        </div>
                        <div className="mb-4">
                           <div className="form-group">
                              <input
                                 type="text"
                                 name="dept_objective_name"
                                 // value={formValues.name}
                                 // onChange={handleChange}
                                 maxLength="30"
                                 className="form-control mt-2"
                                 placeholder="Eg: Timely Delivery"
                                 id="exampleInputfirstname"
                                 value={formik.values.dept_objective_name}
                                 onChange={formik.handleChange}
                              />
                           </div>
                           {formik.errors.dept_objective_name && formik.touched.dept_objective_name ? (
                              <div className="text-danger">{formik.errors.dept_objective_name}</div>
                           ) : null}
                        </div>
                        <div className="mb-4">
                           <div className="form-group">
                              <textarea
                                 className="form-control"
                                 name="dept_objective_description"
                                 placeholder="
                      Description of objective"
                                 id="floatingTextarea"
                                 rows="4"
                                 maxLength="100"
                                 value={formik.values.dept_objective_description}
                                 onChange={formik.handleChange}
                              ></textarea>
                           </div>
                           {formik.errors.dept_objective_description && formik.touched.dept_objective_description ? (
                              <div className="text-danger">{formik.errors.dept_objective_description}</div>
                           ) : null}
                        </div>
                        {!isEditing ? (
                           <div className="text-center">
                              <button type="submit" className="btn set-objective-button w-100" onClick={formik.handleSubmit}>
                                 Publish
                              </button>
                           </div>
                        ) : (
                           <div className="d-flex justify-content-between">
                              <button
                                 type="submit"
                                 className="btn set-objective-button px-5"
                                 onClick={(event) => handleCancel(event)}
                              >
                                 Cancel
                              </button>
                              <button
                                 type="submit"
                                 className="btn sign-up-button-color text-white px-5"
                                 onClick={formik.handleSubmit}
                                 disabled={isloading}
                              >
                                 {isloading ? "Please Wait..." : "Update"}
                              </button>
                           </div>
                        )}
                     </form>
                  </div>
                  <div className="col-xl-6 ps-xl-5">
                     <p className="textBlueLight">Here are the list of your departments and their departmental leaders</p>
                     {DepartmentList?.length == 0 ? (
                        <div className="row">
                           <div className="col-12 d-flex justify-content-center align-items-center py-5">
                              <img src={setObjective} />
                           </div>
                        </div>
                     ) : (
                        <>
                           {DepartmentList &&
                              DepartmentList.map((item, index) => {
                                 {
                                    /* console.log(item); */
                                 }
                                 {
                                    /* console.log(
                        "Edit ***********",
                        item?.objective_id?.objectiveId
                      ); */
                                 }
                                 return (
                                    <div className="row d-flex align-items-center mb-1" key={index}>
                                       <div className="col-9">
                                          <div className="setObjectiveListBox p-3">
                                             <div className=" row">
                                                <div className="col-2">
                                                   <img src={improveProductivity} className="Width-20 mb-2" />
                                                   <br />
                                                   <img src={timelyDelivery} className="Width-20" />
                                                </div>
                                                <div className="col-10 setObjectiveListBox">
                                                   <h4 className="textBlueLight1 mb-2">{item?.objective_id?.objectiveName}</h4>
                                                   <h5 className="textBlueLight1">{item?.dept_objective_name}</h5>
                                                   <p className="textSecondary">{item?.dept_objective_description}</p>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-3">
                                          <div className="d-flex btn gap-2">
                                             <img
                                                width={36}
                                                src={editIcon}
                                                onClick={() => {
                                                   console.log("Edit Objective ***********", item);
                                                   handleEdit(item?.dept_objective_name);
                                                }}
                                             />
                                             <button
                                                type="button"
                                                className="btn p-0"
                                                data-bs-toggle="modal"
                                                id="DeleteDepartemnt"
                                                data-bs-target="#staticBackdrop1"
                                                onClick={() => handleDelete(item?.dept_objective_name)}
                                             >
                                                <img src={deleteIcon} width={36} />
                                             </button>
                                          </div>
                                       </div>
                                    </div>
                                 );
                              })}
                        </>
                     )}
                     <button
                        className={`btn ${
                           !DepartmentList?.length ? "sign-up-button-color-onempty" : "sign-up-button-color"
                        } text-light w-100 mt-3`}
                        onClick={() => handleNextButton()}
                        disabled={!DepartmentList?.length}
                     >
                        Next
                     </button>
                  </div>
               </div>
            </div>
         </div>
         {/* delete modal
          */}
         <div
            className="modal fade"
            id="staticBackdrop1"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            // tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
         >
            <div className="modal-dialog modal-dialog-centered bg-transparent selfModelWidth deleteModalC">
               <div className="modal-content border-0 p-5">
                  <div className="modal-body text-start text-center">
                     <h2 className="textGrey">Are you sure you want to delete this?</h2>
                     <div className="d-flex justify-content-center mt-3">
                        <button className="btn btnOrganization w-auto px-5 mt-3 me-3 " data-bs-dismiss="modal" aria-label="Close">
                           Cancel
                        </button>
                        <button className="btn sign-up-button-color text-white px-5 mt-3" onClick={() => deleteDepartment()}>
                           Delete
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default SetObjective;
