import React from "react";
// import { useNavigate } from "react-router-dom";
import loginImage from "../../assets/images/background/login-page-png.png"

export default function LoginBanner() {
  // const navigate=useNavigate();
  return (
    <div className="col-lg-6 col-12 vh-100 loginBanner">
      <div className="row d-flex align-items-end h-100">
        {/* <div className=" d-flex flex-column justify-content-between align-items-start h-100"> */}
          {/* <div>
            <a to="/" className="text-decoration-none"  onClick={()=>navigate(-1)}>
              <div className="Login-back-button rounded-circle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.872"
                  height="17.269"
                  viewBox="0 0 9.872 17.269"
                >
                  <path
                    id="Icon_ionic-ios-arrow-back"
                    data-name="Icon ionic-ios-arrow-back"
                    d="M14.227,14.826,20.761,8.3a1.234,1.234,0,1,0-1.748-1.743l-7.4,7.4a1.232,1.232,0,0,0-.036,1.7L19.008,23.1a1.234,1.234,0,1,0,1.748-1.743Z"
                    transform="translate(-11.251 -6.194)"
                    fill="#fff"
                  />
                </svg>
              </div>
            </a>
            <span className="hiddenBannerText">EASYOKR</span>
          </div>

          <div>
           
            <h2 className="mb-0 mt-4 textBlue">
            Drive Success Like Never Before With
            </h2>
            <h1 className="bannerHeading">
            EASY<span>OKR</span>
            </h1>
          </div> */}
          <h2 className="text-new-black ">
            Drive Success Like <br /> Never Before With EasyOkr
          </h2>
          <img style={{ objectFit: "cover" }} src={loginImage} height="100%" alt="login page " />
        </div>
      {/* </div> */}
    </div>
  );
}
