/* eslint-disable */
import React, { useEffect, useState } from "react";
import AppLayout from "../Layout/AppLayout";
import organizationMession from "../../assets/images/Icons/SVG/BlueIcons -20-08.svg";
import organizationVision from "../../assets/images/Icons/SVG/BlueIcons -06-47.svg";

import departmentImg from "../../assets/images/Icons/SVG/Group 22974.svg";
import API from "../../Api/Api";
import { useNavigate } from "react-router-dom";

export default function Organization() {
  const [ObjectiveList, setObjectiveList] = useState(null);
  useEffect(() => {
    getOrgObjectiveList();
  }, []);
  const navigate = useNavigate();

  const getOrgObjectiveList = () => {
    API.Dashboard.DashboardOrgObjectiveList()
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log(response);
          setObjectiveList(response.data.data[0]);
        }
      })
      .catch((err) => {
        if (err.response.statusCode === 403) {
          navigate("/");
        }
        console.log(err);
      });
  };
  return (
    <>
      <AppLayout>
        <div className="main-content mt-4">
          <div className="page-content mb-5">
            <div className="row welcomeHeading">
              <div className="col-12">
                <h3 className="mb-0 fw-bold">Organization</h3>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-xl-5 mb-4">
                <h4 className="textDarkBlack">
                  List of organizational objective
                </h4>
                <div className="progressCard p-lg-4 p-2 mb-1">
                  {ObjectiveList &&
                    ObjectiveList?.companyDetails &&
                    ObjectiveList?.companyDetails.map((item, index) => {
                      return (
                        <div className="bg-light-blue p-3 mb-2" key={index}>
                          <p className="textBlueLight1 mb-0 fw-bold">
                            {item?.objective_name}
                          </p>
                          <p className="textDarkBlack mb-0">
                            {item?.description}
                          </p>
                        </div>
                      );
                    })}
                </div>
                <div className="mt-4" id="organizationModal">
                  {/* Button trigger modal */}
                  {/* <button
                    type="button"
                    className="btn btnOrganization py-sm-3 p-1"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    <i className="fa-solid fa-plus plus-circle me-3"></i>
                    Add a new Organization Objective
                  </button>
                
                  <div
                    className="modal fade"
                    id="staticBackdrop"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="modal-header border-0">
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                        <div className="modal-body px-5">
                          <h3 class="textDarkBlack mb-0">Add Objective</h3>
                          <small className="textLightGrey">
                            Once the organizational objective is established, it
                            remains relatively immutable, as alterations could
                            significantly impact the department-level
                            objectives.
                          </small>
                          <form>
                            <div className="mb-4 mt-4">
                              <div className="form-group">
                                <label className="textLightGrey mb-1">Objective Title</label>
                                <input
                                  type="text"
                                  className="form-control mt-2"
                                  placeholder="Eg: Improve Productivity"
                                  id="exampleInputfirstname"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="textLightGrey mb-2">
                                Objective Description
                              </label>
                              <textarea
                                className="form-control"
                                placeholder="Write a one liner description of the above objective..."
                                id="floatingTextarea"
                                rows="2"
                              ></textarea>
                            </div>
                            <button type="submit" className="btn sign-up-button-color text-white px-5 mt-3">Publish</button>
                          </form>
                        </div>
                        <div className="modal-footer border-0">
                        
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-xl-7">
                <h4 className="textDarkBlack mb-3">My Company Details</h4>
                <div className="row">
                  <div className="col-12">
                    <div className="row mb-3">
                      <div className="col-6">
                        <p className="text-center fs-5 textBlueLight1 fw-bold mt-3">
                          {ObjectiveList && ObjectiveList?.organization_name}
                        </p>
                      </div>
                      <div className="col-6 d-flex align-items-center justify-content-center">
                        <img
                          src={
                            ObjectiveList && ObjectiveList?.companyLogoUrl
                              ? `${process.env.REACT_APP_BASE_URL}/images/${ObjectiveList?.companyLogoUrl}`
                              : departmentImg
                          }
                          className="Organization-img-square  float-end me-1"
                        />
                      </div>
                    </div>

                    <p className="textSecondary">
                      {ObjectiveList && ObjectiveList?.about_us}
                    </p>
                    <div className="bg-light-blue border p-3 mb-4">
                      <div className="d-lg-flex align-items-center">
                        <div className="text-center mb-3">
                          <img src={organizationMession} />
                          <p className="textBlack mb-0 mt-2">Mission</p>
                        </div>

                        <p className="textSecondary mb-0 ms-3">
                          {ObjectiveList && ObjectiveList?.mission}
                        </p>
                      </div>
                    </div>
                    <div className="bg-light-blue border p-3 mb-4">
                      <div className="d-lg-flex align-items-center">
                        <div className="text-center mb-3">
                          <img src={organizationVision} />
                          <p className="textBlack mb-0 mt-2">Vision</p>
                        </div>

                        <p className="textSecondary mb-0 ms-3">
                          {ObjectiveList && ObjectiveList?.vision}
                        </p>
                      </div>
                    </div>
                    <div className="bg-light-blue bg-transparent border p-3 mb-4">
                      <h5 className="textDarkBlack mb-3">
                        Our 3 Differentiator
                      </h5>
                      <div className="row">
                        <div className="col-lg-4 mb-3">
                          <p className="textBlueLight1 mb-0 differentiator py-2">
                            {ObjectiveList &&
                              ObjectiveList?.differentiator &&
                              ObjectiveList?.differentiator?.firstKeyFactor}
                          </p>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <p className="textBlueLight1 mb-0 differentiator py-2">
                            {ObjectiveList &&
                              ObjectiveList?.differentiator &&
                              ObjectiveList?.differentiator?.secondKeyFactor}
                          </p>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <p className="textBlueLight1 mb-0 differentiator py-2">
                            {ObjectiveList &&
                              ObjectiveList?.differentiator &&
                              ObjectiveList?.differentiator?.thirdKeyFactor}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="bg-light-blue bg-transparent border p-3 mb-4">
                      <h5 className="textDarkBlack mb-3">
                        Tell us your purpose with OKR ?
                      </h5>
                      <p className="textSecondary">
                        {ObjectiveList && ObjectiveList?.purpose}
                      </p>
                    </div>
                    <div className="bg-light-blue bg-transparent border p-3 mb-4">
                      <h5 className="textDarkBlack mb-3">
                        What problem are we solving?
                      </h5>
                      <p className="textSecondary">
                        {ObjectiveList && ObjectiveList?.solution}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </>
  );
}
