/* eslint-disable */
import React, { useState } from "react";
import LoginBanner from "../comman/LoginBanner";
// import { NavLink } from "react-router-dom";
// import Validation from "../../Constants/Regex";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import API from "../../Api/Api";
import { useNavigate } from "react-router-dom";
import easyokrlogo from "../../assets/images/SVG/main-logo.svg"
export default function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const validate = (values) => {
    console.log(values, "value");
    const regex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

    const errors = {};
    if (!values.email) {
      errors.email = "Please enter email";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email address";
    }

    console.log("Erroes", errors);
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      console.log(values, "hello values");
      createLogin();
    },
    validate,
  });

  const createLogin = () => {
    setLoading(true);

    API.Auth.LoginAPI({
      data: {
        email: formik.values.email,
      },
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          // localStorage.setItem('LoginToken', response.data.data.token)

          formik.resetForm();
          toast.success(response?.data?.message);

          // const stayLoggedIn = document.getElementById('flexCheckDefault').checked;

          // if (stayLoggedIn) {
          //   // Store a flag indicating the user wants to stay logged in
          //   localStorage.setItem('isLoggedIn', true);
          // }

          navigate(`/verify-otp`);
          localStorage.setItem("EmailID", formik.values.email);
          localStorage.setItem(
            "DepartmentUser",
            JSON.stringify(response.data.data)
          );
          setLoading(false);
        } else if (response.data.statusCode === 409) {
          formik.setFieldError("email", err.response.data.message);
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
        // setApierror(err.response.data.message);
        if (err.response && err.response.data && err.response.data.message) {
          // Set an error for the 'email' field
          formik.setFieldError("email", err.response.data.message);
        }
        setLoading(false);
      });
  };
  return (
    <div className="login-page">
      <div className="container-fluid main-container vh-100 p-0">
        <div className="row h-100 position-relative">
          <LoginBanner />

          <div className="col-lg-6 col-12 vh-100">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="login-form-container">
                <div className="d-flex justify-content-center">
                  <img src={easyokrlogo} height={130} width={130} alt="EasyOkr logo" />
                </div>
                <h2 className="mb-2">Login </h2>
                <div className="mb-4"></div>

                {/* { Object.keys(formErrors).length === 0 && isSubmit ? (<div> Signed in successfully</div>) : ( <pre>{JSON.stringify(formValues)}</pre>) } */}

                <form>
                  <div className="mb-2">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Enter registered E-Mail ID
                    </label>

                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      autoComplete="off"
                      placeholder="Enter your E-Mail ID"
                      className="form-control formControl"
                      aria-describedby="emailHelp"
                    />

                    {formik.errors.email && formik.touched.email ? (
                      <div className="text-danger">{formik.errors.email}</div>
                    ) : null}
                  </div>

                  <button
                    type="submit"
                    onClick={formik.handleSubmit}
                    className="btn sign-up-button-color text-white w-100 mt-2"
                    disabled={loading}
                  >
                    {loading ? "Please wait..." : "Verify via OTP"}
                  </button>

                  {/* <p>{message}</p> */}

                  {/* <div className="form-check mt-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Stay Logged In
                    </label>
                  </div> */}
                  {/* <div className="mt-4">
                    <span className="">Don’t have an account?</span>
                    <br />
                    <a
                      className="btn p-0 text-decoration-underline fw-600"
                      href="#/accept-invite"
                    >
                      Let’s Register
                    </a>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
