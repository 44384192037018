/* eslint-disable */
import React, { useState } from "react";
import uploatFileImg from "../../../assets/images/Icons/SVG/Group 22767.svg";
import manualSetup from "../../../assets/images/Icons/SVG/Group 22719.svg";
import ExcelFile from "../../../assets/images/excelfile.png";
import { NavLink } from "react-router-dom";
import RouteName from "../../../Routes/Routename";
import { useNavigate } from "react-router-dom";
import API from "../../../Api/Api";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EmailVerification = (props) => {
   const [isActive, setIsActive] = useState(false);
   const navigate = useNavigate();
   const adminObject = localStorage.getItem("DepartmentToken");
   const DepatmentUser = JSON.parse(localStorage.getItem("DepartmentUser"));
   const [selectedDiv, setSelectedDiv] = useState(null);
   const [enableButton, setEnableBtn] = useState(false);
   const [selectedFile, setSelectedFile] = useState(null);
 
   const handleDivClick = (divId) => {
      setSelectedDiv(divId);
      if (divId === 1) {
         setEnableBtn(true);
      } else if (divId === 2) {
         setEnableBtn(true);
         setSelectedFile("");
      }
   };

   const hanldeNext = async () => {
      if (selectedFile) {
         try {
            const formData = new FormData();
            formData.append("excelFile", selectedFile);
            formData.append("departmentId", DepatmentUser._id);
            formData.append("organizationId", DepatmentUser.organization_id);

            const response = await axios.post(
               `${process.env.REACT_APP_BASE_URL}/department_objective/save_deptData_by_excel`,
               formData,
               {
                  headers: {
                     "Content-Type": "multipart/form-data",
                     Authorization: `Bearer ${adminObject}`,
                  },
               }
            );
            if (response.data.statusCode === 200) {
               // Handle the success response from the backend as needed
               // updateProfileImg(response?.data?.filename);
               // toast.success(response.data.message);
          localStorage.setItem("DepartmentToken", response?.data?.token);
               setTimeout(() => {
                  navigate("/dashboard");
               }, 1000);

               setEnableBtn(true);
               // navigate("/dashboard")
            }
         } catch (error) {
            console.error("Error uploading file:", error);

            if (error.response) {
               // The request was made, but the server responded with a status code
               // that falls out of the range of 2xx
               console.error("Response data:", error.response.data);

               // Display the error message to the user
               toast.error(error.response.data.message);
            } else if (error.request) {
               // The request was made but no response was received
               console.error("No response received:", error.request);
            } else {
               // Something happened in setting up the request that triggered an Error
               console.error("Request setup error:", error.message);
            }
         }
      } else {
         changeDepartmentFlag();
         props.handleNext();
         navigate(`../${RouteName.Auth.Registration}?id=2`, { replace: true });
      }
   };

   const downloadExcel = () => {
      const data={
         organization_id:DepatmentUser.organization_id
      }
      API.Auth.DownloadExcel({data})
         .then((response) => {
            if (response.data.statusCode === 200) {
               // Extract the filename from the response
               const xlsxFileName = response?.data?.data?.xlsxFileName;
               console.log(response.data.data);
               // Construct the full URL to download the file
               const fileURL = `${process.env.REACT_APP_BASE_URL}/images/${xlsxFileName}`;

               console.log(fileURL, xlsxFileName, "fileURL");
               // Create a link element
               const link = document.createElement("a");

               // Set the link's href to the file URL
               link.href = fileURL;

               // Set the link's download attribute to the desired filename
               link.download = xlsxFileName;

               // Append the link to the document
               document.body.appendChild(link);

               // Trigger a click on the link to initiate the download
               link.click();

               // Remove the link from the document
               document.body.removeChild(link);
            }
         })
         .catch((err) => {
            console.log(err);
         });
   };
   const uploadExcelFile = async (event) => {
      if (event.target.files[0]) {
         setSelectedFile(event.target.files[0]);
      }
   };

   const changeDepartmentFlag = () => {
      API.Auth.RegisterAPI({
         data: {
            flag: 2,
            departmentId: props?.UsersData?._id,
         },
      })
         .then((response) => {
            console.log(response, "response");
         })
         .catch((err) => {
            console.log(err);
         });
   };

   return (
      <div className="mt-4">
         <div className="row justify-content-center mt-4">
            <div className="col-12">
               {" "}
               <h2 className="textBlue"></h2>
               <h2 className="textBlack fw-400">Choose either of the below</h2>
               <span className="lightGrey">Kindly select your setup mode type</span>
               <div className="row mt-5">
                  <div className="col-lg-6" onClick={() => handleDivClick(1)}>
                     <div
                        className={`progressCard p-3 mb-3 ${selectedDiv === 1 ? "border-primary" : "border-0"}`}
                        style={{ border: "2px solid" }}
                     >
                        <div className="row">
                           <div className="col-7" id="uploadpackage">
                              <div className="addUserPic mx-auto mt-1 mb-1">
                                 <div className="d-flex align-items-center justify-content-center h-100">
                                    {selectedFile ? (
                                       <span className="text-center">
                                          <img src={ExcelFile} alt="excel-file" className="excel-file-image" />
                                          <p className="selected-file-name">{selectedFile.name}</p>
                                       </span>
                                    ) : (
                                       <span className="text-center">
                                          <i className="fa-solid fa-arrow-up-from-bracket lightGrey font-size-18 mb-2"></i>
                                          <p className="lightGrey">Upload File</p>
                                       </span>
                                    )}
                                 </div>
                                 <label
                                    className={`custom-file-label mb-0 ${selectedFile ? "text-green" : "text-white"}`}
                                    htmlFor="customFile"
                                 >
                                    {"  "}
                                 </label>
                                 <input
                                    type="file"
                                    className="custom-file-input"
                                    id="customFile"
                                    name="media"
                                    multiple=""
                                    accept=".xls, .xlsx"
                                    onChange={(e) => uploadExcelFile(e)}
                                 />
                              </div>
                           </div>

                           <div className="col-5">
                              <img src={uploatFileImg} />
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-6" onClick={() => handleDivClick(2)}>
                     <div
                        className={`progressCard p-3 mb-3 ${selectedDiv === 2 ? "border-primary" : "border-0"}`}
                        style={{ border: "2px solid" }}
                     >
                        <div className="row my-2 pb-2">
                           <div className="col-7" id="uploadpackage">
                              <div className="d-flex align-items-center justify-content-center h-100 mt-1 mb-1">
                                 <span className="text-center">
                                    {/* <i className="fa-solid fa-arrow-up-from-bracket lightGrey font-size-24 mb-3"></i> */}
                                    <i className="fa-solid fa-gear lightGrey font-size-18 mb-2"></i>
                                    <p className="lightGrey">Manual Set Up</p>
                                 </span>
                              </div>
                              {/* <label
                        className="custom-file-label mb-0"
                        htmlFor="customFile"
                      ></label>
                      <input
                        type="file"
                        className="custom-file-input"
                        id="customFile"
                        name="media"
                        multiple=""
                        accept="image/*"
                      /> */}
                           </div>
                           <div className="col-5">
                              <img src={manualSetup} />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-lg-6">
                     <span className="lightGrey">
                        Download this <NavLink onClick={() => downloadExcel()}>OKR Template.xls</NavLink> and upload a filled
                        template here.
                     </span>
                  </div>
                  <div className="col-lg-6">
                     <span className="lightGrey">Set up your OKR manually</span>
                  </div>
               </div>
               <div className="row mt-4">
                  <div className="col-12 text-center">
                     <button
                        className={`btn ${
                           selectedDiv === null || enableButton === false
                              ? "sign-up-button-color-onempty"
                              : "sign-up-button-color"
                        } text-light mt-3 px-5`}
                        onClick={() => hanldeNext()}
                        disabled={selectedDiv === null || enableButton === false}
                     >
                        Next
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default EmailVerification;
