/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RouteName from "../../../Routes/Routename";
import API from "../../../Api/Api";

const CompanyDetail = (props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const departmentId = queryParams.get("departmentId");
  const [UsersData, setUsersData] = useState(null);
  const navigate = useNavigate(); // Move the useNavigate hook here

  // const AcceptInviteGet = async () => {
  //   if (departmentId) {
  //     try {
  //       const response = await API.Auth.AcceptInviteGetAPI(departmentId);
  //               if (response.data.statusCode === 200) {
  //         setUsersData(response.data.data);
  //         localStorage.setItem("DepartmentToken", response.data.token);
  //         if (response.data.data.flag === 0) {
  //           navigate(`../${RouteName.Auth.Registration}?id=0`, {
  //             replace: true,
  //           });
  //           props.setActiveStep(0);
  //         } else if (response.data.data.flag === 1) {
  //           navigate(`../${RouteName.Auth.Registration}?id=1`, {
  //             replace: true,
  //           });
  //           props.setActiveStep(1);
  //         } else if (response.data.data.flag === 2) {
  //           navigate(`../${RouteName.Auth.Registration}?id=2`, {
  //             replace: true,
  //           });
  //           props.setActiveStep(2);
  //         } else if (response.data.data.flag === 3) {
  //           navigate(`../${RouteName.Auth.Registration}?id=3`, {
  //             replace: true,
  //           });
  //           props.setActiveStep(3);
  //         } else if (response.data.data.flag === 4) {
  //           navigate("/dashboard");
  //         }
  //       } else if (response.data.statusCode === 500) {
  //         // toast.error(response?.data?.message);
  //         navigate("/");
  //       }
  //     } catch (err) {
  //       navigate("/");
  //       console.log(err);
  //     }
  //   } else {
  //     console.log("null");
  //   }
  // };

  // useEffect(() => {
  //   AcceptInviteGet();
  // }, [departmentId]);

  const initialValue = {
    fullName: "",
    email: UsersData?.email ? UsersData?.email : "",
  };
  const [formValues, setformValues] = useState(initialValue);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  console.log(
    props?.UsersData?.email ? props?.UsersData?.email : "",
    formValues?.email
  );
  // const [companyData, setCompanyData] = useEffect();
  // const [isError, setIsError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
      setLoading(true);
      API.Auth.RegisterAPI({
        data: {
          email: props?.UsersData?.email
            ? props?.UsersData?.email
            : formValues.email,
          fullName: formValues.fullName,
          flag: 1,
          departmentId: props?.UsersData?._id,
        },
      })
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            localStorage.setItem(
              "DepartmentUser",
              JSON.stringify(response.data.data)
            );

            props.handleNext();
            navigate(`../${RouteName.Auth.Registration}?id=1`, {
              replace: true,
            });
            setLoading(false);
            // toast.success(response?.data?.message);
          } else {
            // toast.error(response?.data?.message);
            setFormErrors({ api: response?.data?.message });
          }
        })
        .catch((err) => {
          console.error("API Error:", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [formErrors, isSubmit, formValues]);

  const validate = (values) => {
    console.log(values);
    const error = {};
    // const regex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

    if (!values.fullName) {
      error.fullName = "Please enter fullName";
    }
    // if (!values.email) {
    //   error.email = "Please enter email";
    // } else if (!regex.test(values.email)) {
    //   error.email = "This is not a valid email format";
    // }
    return error;
  };

  return (
    <div className="mt-5">
      <h2 className="textBlack fw-400 lh-base">
        <span className="textGrey h3 fw-400 ">Welcome to</span>
        <br /> Qodequay Technologies
      </h2>
      <span className="lightGrey">Set- up your company profile</span>
      <div className="row mt-5">
        <div className="col-10">
          <form>
            <div className="mb-4">
              <div className="form-group">
                <label htmlFor="firstname">
                  {" "}
                  <span className="mandatory-star me-1">*</span> Enter your full
                  name
                </label>
                <input
                  type="text"
                  name="fullName"
                  className="form-control mt-2"
                  placeholder="Enter your full name"
                  value={formValues.fullName}
                  onChange={handleChange}
                />
                <p className="text-danger font-13 pt-1 mb-0 ">
                  {formErrors.fullName}
                </p>
              </div>
            </div>
            <div className="mb-4">
              <div className="form-group">
                <label htmlFor="Email1">
                  <span className="mandatory-star me-1">*</span> Enter your
                  email address
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={props?.UsersData?.email}
                  onChange={handleChange}
                  autoComplete="off"
                  placeholder="Enter your email address"
                  className="form-control mt-2"
                  aria-describedby="emailHelp"
                  readOnly={departmentId ? "readOnly" : null}
                />
                {/* 
                <p className="text-danger font-13 pt-1 mb-0 ">
                  {formErrors.email}
                </p> */}
              </div>
            </div>
            <div className="text-center">
              <button
                type="submit"
                onClick={handleSubmit}
                className="btn sign-up-button-color text-white px-5"
                // onClick={handleNext}
                // disabled={loading}
              >
                {loading ? "Please wait..." : "Next"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetail;
